import { LoadingPage, userInfo$ } from '@gmini/common'
import * as smApi from '@gmini/sm-api-sdk'

import { useParams } from 'react-router-dom'

import { useStore } from 'effector-react'

import { useEffect, useMemo } from 'react'

import { omniSubscriptionService } from '@gmini/helpers'

import { fetchMostRecentIssue } from '../../issue.store'

import { fetchCompanyList } from '../../company.store'
import { fetchStatusList } from '../../issueStatus.store'
import { fetchRoleList } from '../../role.store'
import { fetchAllUserList, fetchProjectUserList } from '../../user.store'
import { fetchProjectList, projectList$ } from '../IssueManagerPage/model'

import { attributesService } from '../../attribute.store'

import { EditIssue } from './EditIssue'

import { currentIssue$ } from './model'

export const EditIssuePage = () => {
  const { issueId: paramIssueId } = useParams<{ issueId: string }>()

  const currentIssue = useStore(currentIssue$)
  const userInfo = useStore(userInfo$)
  const projectList = useStore(projectList$)

  const projectUrn = currentIssue?.projectUrn
  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn) || null,
    [projectList, projectUrn],
  )
  useEffect(() => {
    ;(async () => {
      if (!currentIssue) {
        await fetchMostRecentIssue({
          id: window.xprops?.issueId || Number(paramIssueId),
        })
        omniSubscriptionService.fetchList({
          projectUrn: projectUrn || '',
          moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
          resourceName: smApi.Omni.ResourceName.ISSUE,
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchRoleList.submit()
    fetchCompanyList.submit()
    fetchAllUserList.submit({})
    fetchStatusList.submit()
    fetchProjectList.submit()
    if (projectUrn) {
      attributesService.fetchAttributeList({ projectUrn })
    }
  }, [projectUrn])

  useEffect(() => {
    if (projectUrn) {
      fetchProjectUserList.submit({ projectUrn })
    }
  }, [projectUrn])

  if (!userInfo || !currentIssue || !selectedProject || !projectUrn) {
    return <LoadingPage />
  }

  return (
    <EditIssue
      additionalContentWrapperStyle={
        window.xprops?.additionalContentWrapperStyle || { paddingTop: '40px' }
      }
      wrapperStyle={window.xprops?.wrapperStyle}
      baseContentWrapperStyle={window.xprops?.baseContentWrapperStyle}
      issue={currentIssue}
      selectedProject={selectedProject}
      userInfo={userInfo}
      projectUrn={projectUrn}
      origin={window.xprops?.origin}
      onUpdateIssueHandler={window.xprops?.onUpdateIssue}
      onCopyIssueLink={window.xprops?.onCopyIssueLink}
      renderFileViewer={window.xprops?.renderFileViewer}
      renderFileValidationErrorsPopup={
        window.xprops?.renderFileValidationErrorsPopup
      }
      renderIssueOfficialReplyForm={window.xprops.renderIssueOfficialReplyForm}
      onNextIssueHandler={window.xprops.switchIssueProps?.onNextIssue}
      onPrevIssueHandler={window.xprops.switchIssueProps?.onPrevIssue}
      nextDisabled={window.xprops.switchIssueProps?.nextDisabled}
      prevDisabled={window.xprops.switchIssueProps?.prevDisabled}
    />
  )
}
