import { useStore } from 'effector-react'
import moment from 'moment'

import { CapitalizedTextWrapper, LongText, Truncate } from '@gmini/ui-kit'

import { ReactNode } from 'react'

import {
  AssigneeListItem,
  buildShortUserLabel,
  buildUserLabel,
  defaultIssueStatusColor,
  defaultIssueStatusTextColor,
  issueAnswerStatusDescMap,
  issueStatusBgColorMap,
  issueStatusTextColorMap,
  useAssignees,
} from '@gmini/helpers'

import { declensionsOfNum } from '@gmini/utils'

import * as api from '@gmini/ism-api-sdk'

import { RenderAssignees, SimpleBubble, UserBadge } from '@gmini/components'

import { Skeleton } from '@mui/material'

import {
  FULL_DISPLAY_DATE_FORMAT,
  DEFAULT_DISPLAY_DATE_FORMAT,
} from '../../../constants'

import { allUserList$, projectUserList$ } from '../../user.store'

import { fetchIssueHistoryPending$, issueHistory$ } from '../../issue.store'

import { issueStatusList$ } from '../../issueStatus.store'

import {
  assigneeAllUserList$,
  assigneeRoleList$,
  assigneeCompanyList$,
} from '../../assigneeGroupList'

import { attributesService } from '../../attribute.store'

import {
  HistoryItem,
  ChangeInfo,
  UserLabel,
  Date,
  Operation,
  Item,
  ItemLabel,
  ItemValue,
  Wrapper,
  ChangeBody,
  FileWrapper,
  NoHistoryMessage,
  Circle,
  FileItem,
  ItemsWrapper,
  Status,
  ItemValueThrough,
  Separator,
  ItemValueInner,
  ArrowRight,
  File,
  VerticalLine,
  FileWithCross,
  ItemAttribute,
  ItemAttributeValue,
} from './EditIssuePopupHistoryChanges.styled'

type EditIssuePopupHistoryChangesProps = {
  issue: api.Issue.IssuePopulated | api.Issue
}

export const EditIssuePopupHistoryChanges = ({
  issue,
}: EditIssuePopupHistoryChangesProps) => {
  const issueHistory = useStore(issueHistory$)
  const fetchIssueHistoryPending = useStore(fetchIssueHistoryPending$)
  const userList = useStore(projectUserList$)
  const allUsersList = useStore(allUserList$)
  const attributeValueById = useStore(attributesService.attributeValueById$)
  const fetchAttributeValuesPending = useStore(
    attributesService.fetchAttributeValuesPending$,
  )
  const attributeById = useStore(attributesService.attributeById$)

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })
  const issueStatusList = useStore(issueStatusList$)

  if (!issueHistory && !fetchIssueHistoryPending) {
    return <NoHistoryMessage>Нет данных об активности</NoHistoryMessage>
  }

  return (
    <Wrapper>
      {issueHistory?.list.map(
        ({ authorId, date, items, operation }, idx, list) => {
          const files = items.filter(api.Issue.isHistoryFileItem)

          const addedFiles = files.filter(
            ({ operation }) => operation === 'ADDED',
          )
          const addedFilesLength = addedFiles.length

          const removedFiles = files.filter(
            ({ operation }) => operation === 'REMOVED',
          )
          const removedFilesLength = removedFiles.length

          const authorHistoryItem =
            userList.find(({ id }) => id === authorId) || null

          const visibleSeparator = list.length > 1 && list.length - 1 !== idx

          const answerItem = items.filter(api.Issue.isHistoryAnswerItem)

          return (
            <>
              <HistoryItem>
                <UserBadge>{buildShortUserLabel(authorHistoryItem)}</UserBadge>
                <ChangeBody>
                  <ChangeInfo>
                    <UserLabel>
                      {authorHistoryItem?.email ||
                        buildUserLabel(authorHistoryItem)}
                    </UserLabel>
                    <Date>{moment(date).format(FULL_DISPLAY_DATE_FORMAT)}</Date>
                  </ChangeInfo>

                  <Operation>
                    <VerticalLine />
                    <Circle />
                    {operationMap[operation]} замечание
                  </Operation>

                  <ItemsWrapper>
                    {items.map((item, idx) => {
                      if (
                        api.Issue.isHistoryFileItem(item) ||
                        api.Issue.isHistoryAnswerItem(item) ||
                        api.Issue.isHistoryItemAttribute(item)
                      ) {
                        return null
                      }

                      if (api.Issue.isHistoryItemAssignee(item)) {
                        const isAssigneeDeleted = (
                          assignee: AssigneeListItem,
                        ) =>
                          item.deletedAssignees.some(
                            deletedAssignee =>
                              deletedAssignee.source === assignee.source &&
                              deletedAssignee.assigneeId ===
                                assignee.assigneeId,
                          )

                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Item key={idx}>
                            <VerticalLine />
                            <Circle />
                            <ItemLabel>Назначено на</ItemLabel>

                            <ItemValue>
                              {operation === 'UPDATED' &&
                                !api.Issue.isHistoryItemStatus(item) && (
                                  <>
                                    <RenderAssignees
                                      assignees={getAssignees(item.oldValue)}
                                      isCrossOutedTooltipText={
                                        isAssigneeDeleted
                                      }
                                      isCrossOutedFirstAssigneeText={
                                        isAssigneeDeleted
                                      }
                                    />

                                    <ArrowRight color='#a2a3b7' />
                                  </>
                                )}
                              <ItemValueInner>
                                <RenderAssignees
                                  assignees={getAssignees(item.newValue)}
                                />
                              </ItemValueInner>
                            </ItemValue>
                          </Item>
                        )
                      }

                      let preparedNewValue: ReactNode = item.newValue ? (
                        <Truncate
                          endLengthSize={5}
                          text={item.newValue.toString()}
                        />
                      ) : (
                        ''
                      )
                      let preparedOldValue: ReactNode = item.oldValue ? (
                        <Truncate
                          endLengthSize={5}
                          text={item.oldValue.toString()}
                        />
                      ) : (
                        ''
                      )
                      let preparedItemLabel: ReactNode =
                        api.Issue.isHistoryItemStatus(item) ||
                        api.Issue.isHistoryItemAttribute(item)
                          ? ''
                          : fieldLabelMap[item.field]

                      if (api.Issue.isHistoryDeadlineItem(item)) {
                        const { oldValue, newValue } = item

                        if (oldValue) {
                          preparedOldValue = (
                            <LongText partSize={15}>
                              {moment(oldValue).format(
                                DEFAULT_DISPLAY_DATE_FORMAT,
                              )}
                            </LongText>
                          )
                        }

                        if (newValue) {
                          preparedNewValue = (
                            <LongText partSize={15}>
                              {moment(newValue).format(
                                DEFAULT_DISPLAY_DATE_FORMAT,
                              )}
                            </LongText>
                          )
                        }
                      } else if (api.Issue.isHistoryAuthorItem(item)) {
                        const { oldValue, newValue } = item

                        const [oldAuthorName] = allUsersList.filter(
                          ({ id }) => id === oldValue,
                        )

                        const [newAuthorName] = allUsersList.filter(
                          ({ id }) => id === newValue,
                        )

                        if (oldAuthorName) {
                          preparedOldValue = (
                            <LongText partSize={15}>
                              {oldAuthorName.name}
                            </LongText>
                          )
                        }

                        if (newAuthorName) {
                          preparedNewValue = (
                            <LongText partSize={15}>
                              {newAuthorName.name}
                            </LongText>
                          )
                        }
                      } else if (api.Issue.isHistoryItemStatus(item)) {
                        const { newValue } = item

                        const newStatusColor = newValue
                          ? issueStatusBgColorMap[newValue]
                          : defaultIssueStatusColor

                        const newStatusColorText = newValue
                          ? issueStatusTextColorMap[newValue]
                          : defaultIssueStatusTextColor

                        preparedNewValue = (
                          <Status
                            color={newStatusColorText}
                            background={newStatusColor}
                          >
                            {newValue
                              ? issueStatusList.find(
                                  ({ status }) => status === newValue,
                                )?.name
                              : ''}
                          </Status>
                        )

                        preparedItemLabel =
                          operation === 'UPDATED'
                            ? 'Изменен статус на'
                            : 'Статус'
                      }

                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Item key={idx}>
                          <VerticalLine />
                          <Circle />
                          <ItemLabel>{preparedItemLabel}</ItemLabel>

                          <ItemValue>
                            {operation === 'UPDATED' &&
                              !api.Issue.isHistoryItemStatus(item) && (
                                <>
                                  <ItemValueThrough>
                                    {preparedOldValue}
                                  </ItemValueThrough>
                                  <ArrowRight color='#a2a3b7' />
                                </>
                              )}
                            <ItemValueInner>{preparedNewValue}</ItemValueInner>
                          </ItemValue>
                        </Item>
                      )
                    })}
                    {answerItem.map(({ status, id, description }) => {
                      const itemLabel = issueAnswerStatusDescMap[status]
                      const color = replyStatusColorMap[status] || '#a2a3b7'

                      return (
                        <>
                          <Item key={id}>
                            <VerticalLine />
                            <Circle color={color} />
                            <ItemLabel color={color}>
                              Официальный ответ {itemLabel}
                            </ItemLabel>
                          </Item>
                          <ItemValue m='4.5px 0' size='medium'>
                            {description}
                          </ItemValue>
                        </>
                      )
                    })}

                    {addedFilesLength > 0 && (
                      <HistoryFileList list={addedFiles} operation='ADDED' />
                    )}
                    {removedFilesLength > 0 && (
                      <HistoryFileList
                        list={removedFiles}
                        hiddenVerticalLine={addedFilesLength !== 0}
                        operation='REMOVED'
                      />
                    )}

                    {items.map(item => {
                      if (!api.Issue.isHistoryItemAttribute(item)) {
                        return null
                      }
                      const renderAttribute = (
                        { valueIds, id }: api.Attribute,
                        deleted?: boolean,
                      ) => (
                        <ItemAttribute key={id}>
                          <VerticalLine />
                          <Item>
                            <Circle />
                            <CapitalizedTextWrapper>
                              <ItemLabel>
                                {deleted
                                  ? `${attributeById[id]?.name} удален(а)`
                                  : `${attributeById[id]?.name} добавлен(а)`}
                              </ItemLabel>
                            </CapitalizedTextWrapper>
                          </Item>
                          <ItemAttributeValue>
                            {valueIds.map(id => {
                              const value = attributeValueById[id]
                              if (!value && fetchAttributeValuesPending) {
                                return <Skeleton width={30} height={20} />
                              }

                              return (
                                <SimpleBubble
                                  key={value?.id}
                                  title={value?.name}
                                  deleted={deleted}
                                />
                              )
                            })}
                          </ItemAttributeValue>
                        </ItemAttribute>
                      )

                      return [
                        ...item.deletedAttributes.map(a =>
                          renderAttribute(a, true),
                        ),
                        ...item.addedAttributes.map(a => renderAttribute(a)),
                      ]
                    })}
                  </ItemsWrapper>
                </ChangeBody>
              </HistoryItem>
              {visibleSeparator && <Separator />}
            </>
          )
        },
      )}
    </Wrapper>
  )
}

const replyStatusColorMap: Record<api.IssueAnswer.StatusEnum, string> = {
  DECLINED: '#BF3737',
  ACCEPTED: '#0bbb7b',
  PENDING: '#4c5ecf',
  DELETED: '#a2a3b7',
}

type HistoryFileListProps = {
  list: api.Issue.HistoryFileItem[]
  hiddenVerticalLine?: boolean
  operation: api.HistoryChanges.Operations
}

const HistoryFileList = ({
  list,
  hiddenVerticalLine,
  operation,
}: HistoryFileListProps) => {
  const fileListLength = list.length

  const iconSize = list.length === 1 ? 'medium' : 'small'
  return (
    <>
      <Item>
        {!hiddenVerticalLine && <VerticalLine />}
        <Circle />
        <ItemLabel>
          {operation === 'ADDED' ? 'Добавил(а)' : 'Удалил(а)'} {fileListLength}{' '}
          {declensionsOfNum(fileListLength, ['файл', 'файла', 'файлов'])}
        </ItemLabel>
      </Item>
      <FileWrapper>
        {list.map(({ filename }, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <FileItem key={idx} gap={list.length === 1 ? '8px' : '12px'}>
            {operation === 'ADDED' ? (
              <File size={iconSize} />
            ) : (
              <FileWithCross size={iconSize} />
            )}
            <ItemValueInner>
              <LongText partSize={15}>{filename}</LongText>
            </ItemValueInner>
          </FileItem>
        ))}
      </FileWrapper>
    </>
  )
}

type FieldMap = Record<
  'name' | 'description' | 'status' | 'deadline' | 'author',
  string
>

const fieldLabelMap: FieldMap = {
  description: 'Описание',
  name: 'Название',
  status: 'Статус',
  deadline: 'Срок',
  author: 'Автор',
}

const operationMap: Record<api.HistoryChanges.Operations, string> = {
  ADDED: 'Создал(а)',
  REMOVED: 'Удалил(а)',
  UPDATED: 'Изменил(а)',
}
