import {
  DateFilterItemsCode,
  filterDateEnrichment,
  sortByFieldEntity,
} from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'
import { createEffect, createEvent, merge, sample } from 'effector'
import { clone } from 'ramda'
import { createTableService } from '@gmini/ui-kit'

import { ZERO_SEARCH } from '../../../constants'

import { createIssueTemplateService } from '../../createIssueTemplateListService'

import {
  createIssueTemplate,
  deleteIssueTemplate,
  updateIssueTemplate,
} from '../../issueTemplate.action'
import { fetchAllowedFilters } from '../IssueTemplateListFilterPanel/model'

import { filterService } from '../../issueTemplateFilter.store'

import { matchIssueTemplateToFilters } from './matchIssueTemplateToFilters'

const { appliedFilters$ } = filterService.appliedFilters

sample({
  source: appliedFilters$,
  clock: merge([
    deleteIssueTemplate.doneData,
    updateIssueTemplate.doneData,
    createIssueTemplate.doneData,
  ]),
  fn(filters, issueTemplate) {
    fetchAllowedFilters({ projectUrn: issueTemplate.projectUrn })

    if (!matchIssueTemplateToFilters(issueTemplate, filters)) {
      removeIssueTemplateFromIds({
        id: issueTemplate.id,
        search: filters.filter,
      })
    }
  },
})

export const {
  resetTemplateList,
  templateList$,
  fetchIssueTemplateList,
  ids$,
  byId$,
} = createIssueTemplateService({
  fetchIssueTemplateListApi: api.IssueTemplate.fetchList,
})

export const addIssueTemplateToIds = createEvent<{
  search: string
  id: number
  sortByFieldName?: string | null
  sortByOperator?: api.SortByOperator | null
}>()
export const addTemplateToIdsWithTemplateById = sample({
  clock: addIssueTemplateToIds,
  source: byId$,
  fn: (templateById, params) => ({
    templateById,
    params,
  }),
})
export const removeIssueTemplateFromIds = createEvent<{
  search: string
  id: number
}>()

byId$.on(
  merge([updateIssueTemplate.doneData, createIssueTemplate.doneData]),
  (state, result) => ({
    ...state,
    [result.id]: { ...state[result.id], ...result },
  }),
)

ids$
  .on(addTemplateToIdsWithTemplateById, (state, { params, templateById }) => {
    const next = clone(state)

    const search = params.search || ZERO_SEARCH

    const nextIdsCurrentSearch = [
      ...new Set([params.id, ...(next[search] || [])]),
    ]

    if (params.sortByFieldName && params.sortByOperator) {
      const issueList = nextIdsCurrentSearch.map(id => templateById[id])

      const sortedIssueList = sortByFieldEntity(issueList, {
        sortByFieldName: params.sortByFieldName as keyof api.IssueTemplate,
        sortByOperator: params.sortByOperator,
      })

      next[search] = sortedIssueList.map(({ id }) => id)
    } else {
      next[search] = nextIdsCurrentSearch
    }

    return next
  })
  .on(removeIssueTemplateFromIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = next[search]?.filter(id => id !== result.id) || []

    return next
  })

export type FetchIssueTemplateListWithPrepareDateRange = Omit<
  api.IssueTemplate.FetchListParams,
  'assignees'
> & {
  updatedDateCode?: DateFilterItemsCode | null
  createdDateCode?: DateFilterItemsCode | null
  assignees?: api.Assignee[]
}

export const fetchIssueTemplateListWithEnrichDateFilter = createEffect<
  FetchIssueTemplateListWithPrepareDateRange,
  unknown
>()

fetchIssueTemplateListWithEnrichDateFilter.use(
  ({
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    assignees,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
      filterDateEnrichment(currentDate, {
        updatedDateRange,
        createdDateRange,
        updatedDateCode,
        createdDateCode,
      })

    fetchIssueTemplateList({
      ...otherProps,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
      assignees,
    })
  },
)

export const issueTemplateTableService = createTableService()
