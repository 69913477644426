import {
  passesFilterAssigneeMultiple,
  passesFilterDate,
  passesFilterStatus,
  passesFilterUser,
} from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'

import { filterDeadlineRangeByCode } from '../IssueListFilterPanel/issueListDeadlineOptions'

import { IssueListFilters } from './IssueList.types'

export const matchIssueToFilters = (
  issue: api.Issue.IssuePopulated,
  filters: IssueListFilters,
) => {
  const isPassAssigneeFilter = passesFilterAssigneeMultiple(
    filters.assignees,
    issue.assignees,
  )

  const isPassAuthorFilter = passesFilterUser(
    filters.authorIds || [],
    issue.author,
  )

  const isPassStatusFilter = passesFilterStatus(filters.statuses, issue.status)

  const currentDate = new Date()

  const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
    filterDateEnrichment(currentDate, filters)

  const isPassUpdatedDateFilter = passesFilterDate(
    enrichedUpdatedDateRange,
    issue.updatedAt,
  )

  const isPassCreatedDateFilter = passesFilterDate(
    enrichedCreatedDateRange,
    issue.createdAt,
  )

  const isPassDeadlineFilter = passesFilterDate(
    filters.deadlineCode
      ? filterDeadlineRangeByCode[filters.deadlineCode]?.(currentDate)
      : null,
    issue.deadline,
  )

  const isPassSearchFilter = issue.name
    .toLowerCase()
    .includes(filters.filter.toLowerCase())

  return (
    isPassAssigneeFilter &&
    isPassAuthorFilter &&
    isPassStatusFilter &&
    isPassUpdatedDateFilter &&
    isPassCreatedDateFilter &&
    isPassSearchFilter &&
    isPassDeadlineFilter
  )
}
