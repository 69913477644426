/* eslint-disable react/display-name */
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Button,
  ConfirmActionPopover,
  IconButton,
  PlusCircle,
  Tooltip,
  Update,
  Table,
  TableColumn,
  TableContentContainer,
} from '@gmini/ui-kit'

import moment from 'moment'

import { useSnackbar } from 'notistack'

import { isNotEmpty, useDebounce } from '@gmini/utils'

import { useSortTable } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { useStore, useStoreMap } from 'effector-react'

import { combine } from 'effector'

import { useNavigate } from 'react-router-dom'

import {
  ActionPanel,
  EntityCreatedSnackbar,
  HighlightOffIcon,
  InfiniteScroll,
  Magnifier,
  NameBold,
  RenderAssignees,
  SearchContainer,
  SearchInput,
  TabContainer,
  TableContent,
  RenderAttributes,
} from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import {
  AssigneeListItem,
  IssueTemplateListFiltersType,
  queryParser,
  SEARCH_TL,
  SORT_BY_FIELD_NAME_TL,
  SORT_BY_OPERATOR_TL,
  TEMPLATE_ID,
  useAssignees,
  getListColumnOrderFromStorage,
  IssueTemplateUrlKeyType,
} from '@gmini/helpers'

import qs from 'query-string'

import { dissoc } from 'ramda'

import {
  allUserList$,
  fetchAllUserListPending$,
  projectUserList$,
} from '../../user.store'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  PROJECT_IS_NOT_SELECTED_ERROR,
  PROJECT_URN,
  ZERO_SEARCH,
} from '../../../constants'

import { UpsertIssueTemplatePopup } from '../UpsertIssueTemplatePopup'

import { toggleOpenPopup as toggleOpenUpsertIssueTemplatePopup } from '../UpsertIssueTemplatePopup/upsertIssueTemplatePopup.store'

import {
  createIssueTemplate,
  deleteIssueTemplate,
  fetchIssueTemplateMostRecent,
  updateIssueTemplate,
} from '../../issueTemplate.action'

import { FormValueUpsertIssueTemplate } from '../UpsertIssueTemplatePopup/UpsertIssueTemplatePopup.types'

import { IssueTemplateListFilterPanel } from '../IssueTemplateListFilterPanel'

import { fetchAllowedFiltersPending$ } from '../IssueTemplateListFilterPanel/model'

import { IssueTemplateListAppliedFilters } from '../IssueTemplateListAppliedFilters'

import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeRoleList$,
} from '../../assigneeGroupList'

import { filterService } from '../../issueTemplateFilter.store'

import { attributesService } from '../../attribute.store'

import { matchIssueTemplateToFilters } from './matchIssueTemplateToFilters'

import {
  addIssueTemplateToIds,
  fetchIssueTemplateList,
  fetchIssueTemplateListWithEnrichDateFilter,
  FetchIssueTemplateListWithPrepareDateRange,
  issueTemplateTableService,
  resetTemplateList,
  templateList$,
} from './model'

type IssueTemplateListProps = {
  projectList: smApi.Project[]
}

export const IssueTemplateList = ({ projectList }: IssueTemplateListProps) => {
  const selectedSortField =
    (queryParser({ key: SORT_BY_FIELD_NAME_TL }) as string) || null
  const selectedSortOperator =
    (queryParser({ key: SORT_BY_OPERATOR_TL }) as string) || null
  const navigate = useNavigate()

  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService
  const { update } = useUpdateFilter()
  const attributeValueById = useStore(attributesService.attributeValueById$)
  const appliedFilters = useStore(appliedFilters$)
  const projectUrn = queryParser({ key: PROJECT_URN }) as string
  const selectedTemplateId = queryParser({
    key: TEMPLATE_ID,
    options: { parseNumbers: true },
  }) as number

  const [searchValue, setSearchValue] = useState(appliedFilters.filter)
  const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)
  const [selectedTemplate, setSelectedTemplate] =
    useState<api.IssueTemplate | null>(null)
  const [templateForDelete, setTemplateForDelete] =
    useState<IssueTemplateTableRow | null>()
  const [offset, setOffset] = useState(defaultOffset)

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const fetchIssueTemplateListCb = useCallback(
    (params: FetchIssueTemplateListWithPrepareDateRange) => {
      fetchIssueTemplateListWithEnrichDateFilter({
        ...appliedFilters,
        projectUrn,
        ...params,
      })
    },
    [appliedFilters, projectUrn],
  )

  const debouncedFetch = useDebounce({
    handler: () => {
      const formattedSearchValue = searchValue.trim().toLowerCase()
      if (
        appliedFilters.filter?.trim().toLowerCase() === formattedSearchValue
      ) {
        return
      }
      resetTemplateList()
      setOffset(0)
      fetchIssueTemplateListCb({
        offset: 0,
        limit,
        filter: formattedSearchValue,
      })
      formattedSearchValue
        ? update({ [SEARCH_TL]: formattedSearchValue })
        : update({ [SEARCH_TL]: '' })
    },
    delay: 500,
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRefCurrent = tableContainerRef.current
  const templatePending = useStore(templatePending$)
  const projectUserList = useStore(projectUserList$)
  const allUserList = useStore(allUserList$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const [columns, setColumns] = useState(getTemplateListColumnOrderFromStorage)

  const { templateList, total, byId } = useStoreMap({
    store: templateList$,
    keys: [searchValue],
    fn: ({ byId$, ids$, totalTemplates$ }, [key]) => {
      const search = key || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          templateList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalTemplates$ || 0,
          byId: byId$,
        }
      }

      return { templateList: [], total: 0, byId: byId$ }
    },
  })

  useEffect(() => () => resetTemplateList(), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedFetch(), [searchValue])

  const listIsNotOver = total > offset

  useEffect(() => {
    if (selectedTemplateId) {
      const openIssueFromLink = async () => {
        const currentIssueTemplate = byId[selectedTemplateId]
          ? byId[selectedTemplateId]
          : await fetchIssueTemplateMostRecent({
              id: selectedTemplateId,
            })
        setSelectedTemplate(currentIssueTemplate)
        toggleOpenUpsertIssueTemplatePopup(true)
      }
      openIssueFromLink()
    }
  }, [byId, selectedTemplateId])

  useEffect(() => {
    setColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    resetTemplateList()
    fetchIssueTemplateListCb({ limit, offset: 0 })
    setOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUrn])

  useEffect(() => {
    // Для пагинации
    if (!projectUrn || offset === 0) {
      return
    }

    fetchIssueTemplateListCb({ limit, offset })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, projectUrn])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: IssueTemplateTableRow
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      onClick: props => {
        const prev = qs.parse(window.location.search)
        const search = qs.stringify({ ...prev, [TEMPLATE_ID]: props.item.id })
        navigate({ search })
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Создать копию',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: props => {
        setAnchorElPopover(props.event.target as Element)
        setTemplateForDelete(props.item)
      },
      icon: Icon.DELETE,
    },
  ])

  const handleClosePopover = () => {
    setAnchorElPopover(null)
    setTemplateForDelete(null)
  }

  const onDeleteTemplate = async () => {
    if (!templateForDelete) {
      return
    }

    await deleteIssueTemplate({
      id: templateForDelete.id,
      version: templateForDelete.version,
    })
    resetTemplateList()
    fetchIssueTemplateListCb({ offset: 0, limit })
    setOffset(0)
    handleClosePopover()
  }

  const resetInfinityScroll = () => {
    tableContainerRefCurrent?.scrollTo(0, 0)
    resetTemplateList()
    fetchIssueTemplateListCb({ limit, offset: 0 })
    setOffset(0)
  }

  const onSort = useCallback(
    (filters: IssueTemplateUrlKeyType) => {
      resetTemplateList()
      update(filters)

      fetchIssueTemplateListCb({
        ...appliedFilters$.getState(),
        offset: 0,
        limit,
      })
    },
    [appliedFilters$, fetchIssueTemplateListCb, update],
  )

  const { renderTh, isSorted, SortContextMenu } = useSortTable({
    selectedSortField,
    selectedSortOperator,
    onSort,
    sortKeysMap: {
      fieldKey: SORT_BY_FIELD_NAME_TL,
      operatorKey: SORT_BY_OPERATOR_TL,
      asc: api.SortByOperator.ASC,
      desc: api.SortByOperator.DESC,
    },
    getFieldKey: (col: TableColumn<IssueTemplateTableRow>) => col.field,
    unSortableFields: columns
      .map(c => c.field)
      .filter(f => !(f in api.IssueTemplate.SortableField)),
  })

  const tableList = useMemo(
    (): IssueTemplateTableRow[] =>
      templateList.map(template => ({
        ...template,
        assignees: getAssignees(template.assignees),
        author: allUserList.find(user => user.id === template.authorId),
      })),
    [templateList, getAssignees, allUserList],
  )

  const onSubmitUpsertIssueTemplate = async ({
    assignees,
    project,
    ...otherData
  }: FormValueUpsertIssueTemplate) => {
    try {
      const projectUrn = project?.urn
      if (!projectUrn) {
        throw new Error('Не выбран проект')
      }

      let issueTemplate: api.IssueTemplate | null = null

      const preparedAttributes = otherData.attributes.map(attribute => ({
        id: attribute.attributeId,
        valueIds: attribute.values.map(({ id }) => id),
      }))

      if (selectedTemplate) {
        issueTemplate = await updateIssueTemplate({
          id: selectedTemplate.id,
          version: selectedTemplate.version,
          assignees,
          ...otherData,
          attributes: preparedAttributes,
        })
      } else {
        issueTemplate = await createIssueTemplate({
          assignees,
          projectUrn,
          ...otherData,
          attributes: preparedAttributes,
        })

        if (!issueTemplate) {
          throw new Error('Not found template')
        }

        const isMatchIssueToFilters = matchIssueTemplateToFilters(
          issueTemplate,
          appliedFilters,
        )

        notifyCreatedIssueTemplate(issueTemplate, isMatchIssueToFilters)

        if (isMatchIssueToFilters) {
          addIssueTemplateToIds({
            id: issueTemplate.id,
            search: appliedFilters.filter,
            sortByFieldName: appliedFilters.sortByFieldName,
            sortByOperator: appliedFilters.sortByOperator,
          })
        }
      }
    } catch (error) {
      console.error('error :>> ', error)
    }
  }

  const onChangeFilter = useCallback(
    (filter: IssueTemplateListFiltersType) => {
      if (!projectUrn) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      const attributes = filter.attributeValueIds?.reduce(
        (acc: Record<number, api.Attribute>, valueId) => {
          if (!attributeValueById?.[valueId]) {
            return acc
          }
          const { id, attribute } = attributeValueById?.[valueId]
          if (!acc[attribute.id]) {
            acc[attribute.id] = { id: attribute.id, valueIds: [id] }
            return acc
          }
          acc[attribute.id].valueIds.push(valueId)

          return acc
        },
        {},
      )

      resetTemplateList()
      fetchIssueTemplateListCb({
        offset: 0,
        limit,
        projectUrn,
        attributes: Object.values(attributes || {}),
        ...filter,
      })
      setOffset(0)
    },
    [attributeValueById, fetchIssueTemplateListCb, projectUrn],
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notifyCreatedIssueTemplate = useCallback(
    (issueTemplate: api.IssueTemplate, isMatchIssueToFilters: boolean) => {
      let infoText: string | null =
        'Измените параметры фильтрации для отображения данного шаблона замечания в общем списке.'

      if (isMatchIssueToFilters) {
        infoText = null
      }

      enqueueSnackbar('', {
        content: key => (
          <EntityCreatedSnackbar
            id={key}
            message={`Новый шаблон замечания “${issueTemplate.name}” успешно создан.`}
            onClose={() => closeSnackbar()}
            infoText={infoText}
          />
        ),
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )

  const isLoadingCol = useCallback(
    (col: TableColumn<IssueTemplateTableRow>) => {
      if (col.field === 'assignees' || col.field === 'author') {
        return fetchAllUserListPending
      }

      return false
    },
    [fetchAllUserListPending],
  )

  return (
    <TabContainer>
      <UpsertIssueTemplatePopup
        issueTemplate={selectedTemplate}
        allUsers={allUserList}
        projectUsers={projectUserList}
        onSubmit={onSubmitUpsertIssueTemplate}
        submitButtonTitle={selectedTemplate ? 'Применить' : 'Создать'}
        title={selectedTemplate ? 'Редактирование шаблона' : 'Новый шаблон'}
        onClose={() => {
          const prev = dissoc(TEMPLATE_ID, qs.parse(window.location.search))
          const search = qs.stringify({ ...prev })
          navigate({ search })
          setSelectedTemplate(null)
        }}
        projectList={projectList}
        projectUrn={projectUrn}
      />
      <ConfirmActionPopover
        message={
          <>
            Вы уверены, что хотите навсегда удалить{' '}
            <NameBold>{selectedTemplate?.name}?</NameBold> Все ссылки на ресурсы
            будут удалены. Это действие не может быть отменено.
          </>
        }
        title='Удалить шаблон'
        anchorEl={anchorElPopover}
        handleClose={handleClosePopover}
        actions={
          <>
            <Button
              size='regular'
              color='secondary'
              onClick={handleClosePopover}
            >
              Отмена
            </Button>
            <Button color='warning' onClick={onDeleteTemplate} size='regular'>
              Удалить
            </Button>
          </>
        }
      />
      <TableContentContainer>
        <IssueTemplateListFilterPanel
          onChange={onChangeFilter}
          projectUrn={projectUrn}
        />
        <TableContent>
          <ActionPanel>
            <SearchContainer style={{ marginRight: 'auto' }}>
              <SearchInput
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                placeholder='Поиск'
                width='230px'
              />
              <Magnifier />
              {searchValue ? (
                <HighlightOffIcon onClick={() => setSearchValue('')} />
              ) : null}
            </SearchContainer>
            <IssueTemplateListAppliedFilters onChange={onChangeFilter} />

            <Tooltip placement='top' title='Обновить список шаблонов'>
              <IconButton
                onClick={resetInfinityScroll}
                disabled={templatePending || !projectUrn}
              >
                <Update color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
            </Tooltip>

            <Button
              data-test-id='createIssueTemplate'
              onClick={() => toggleOpenUpsertIssueTemplatePopup(true)}
              leftIcon={<PlusCircle width='24px' height='24px' />}
              disabled={templatePending}
            >
              Создать шаблон замечания
            </Button>
          </ActionPanel>

          {!templatePending && <ContextMenu />}
          <SortContextMenu />

          <InfiniteScroll
            hasMore={tableList.length !== 0 && listIsNotOver}
            next={() => setOffset(prevValue => prevValue + limit)}
            triggersObserve={[tableList]}
          >
            <Table
              columns={columns}
              rows={tableList}
              tableService={issueTemplateTableService}
              onChangeColumns={setColumns}
              pending={templatePending}
              getRowKey={row => row.id}
              activeRowKey={ctxMenu.item?.item.id}
              totalRows={total || 0}
              onRowCtxMenu={(e, item) => {
                e.preventDefault()

                setCtxMenu({
                  coords: { x: e.clientX, y: e.clientY },
                  item: { item, event: e },
                })
              }}
              onClick={(e, item) => {
                const prev = qs.parse(window.location.search)
                const search = qs.stringify({ ...prev, [TEMPLATE_ID]: item.id })
                navigate({ search })
              }}
              isLoadingCol={isLoadingCol}
              isSelectedCol={isSorted}
              renderTh={renderTh}
            />
          </InfiniteScroll>
        </TableContent>
      </TableContentContainer>
    </TabContainer>
  )
}

const defaultOffset = 0
const limit = 20

type IssueTemplateTableRow = Omit<
  api.IssueTemplate.IssueTemplate,
  'assignees'
> & { assignees: AssigneeListItem[]; author?: smApi.Auth.UserData }

const columns: TableColumn<IssueTemplateTableRow>[] = [
  {
    name: 'ID',
    field: 'id',
    thContent: 'ID',
    type: 'number',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
    cellStyle: { wordBreak: 'break-word' },
    style: { width: 'auto' },
    name: 'Название',
    field: 'name',
    visible: true,
  },
  {
    name: 'Атрибуты',
    visible: true,
    field: 'attributes',
    renderCell: ({ row }) => {
      if (!row.attributes) {
        return null
      }

      return (
        <RenderAttributes
          attributeValueIds={row.attributes.flatMap(({ valueIds }) => valueIds)}
          attributeValueById$={attributesService.attributeValueById$}
          hideDeletedAttributes
        />
      )
    },
    cellStyle: { width: '400px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    renderCell: ({ row }) => (
      <>{moment(row.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}</>
    ),
    name: 'Создан',
    field: 'createdAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.updatedAt
        ? moment(row.updatedAt).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не изменялся',
    name: 'Изменен',
    field: 'updatedAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.daysToDeadline
        ? `${row.daysToDeadline} ${
            row.isWorkdaysToDeadline ? 'рабочих' : 'календарных'
          }`
        : 'Не задан',
    cellStyle: { whiteSpace: 'nowrap' },
    name: 'Срок дней',
    field: 'daysToDeadline',
    visible: true,
  },
  {
    field: 'assignees',
    renderCell: ({ row }) => {
      if (!row.assignees.length) {
        return <>{'Не назначен'}</>
      }
      return <RenderAssignees assignees={row.assignees} />
    },
    name: 'Назначено на',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    renderCell: ({ row }) => {
      if (!row.author) {
        return <>Не найден</>
      }
      return <>{row.author.name}</>
    },
    name: 'Автор',
    field: 'author',
    visible: true,
  },
]

const newKey = 'templateListColumnOrderV2'
const oldKey = 'templateListColumnOrder'

const getTemplateListColumnOrderFromStorage = () =>
  getListColumnOrderFromStorage(newKey, oldKey, columns)

const setColumnsToStorage = (next: TableColumn<IssueTemplateTableRow>[]) => {
  localStorage.setItem(
    newKey,
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}

export const templatePending$ = combine(
  [
    createIssueTemplate.pending$,
    fetchIssueTemplateList.pending$,
    deleteIssueTemplate.pending$,
    updateIssueTemplate.pending$,
    fetchAllowedFiltersPending$,
  ],
  pendings => pendings.some(Boolean),
)

export type TemplateCtxMenuValue = {
  templateID?: string
}
