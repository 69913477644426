import {
  // AllFolders, //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
  Tabs,
} from '@gmini/ui-kit'
import { useStore } from 'effector-react'

import qs from 'query-string'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as smApi from '@gmini/sm-api-sdk'

import { SwitchProject, SwitchProjectItem, userInfo$ } from '@gmini/common'

import {
  // SwitchProjectPopoverItemSeparator, //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
  // SwitchProjectPopoverItemCount,
  // SwitchProjectProjectName,
  SwitchProjectSelectWrapper,
  PageContentWrapper,
} from '@gmini/components'

import { queryParser } from '@gmini/helpers'

import {
  IssueList,
  IssueManagerWrapper,
  IssueTemplateList,
} from '../../organisms'

import { PROJECT_URN, TabIndex, SELECTED_TAB } from '../../../constants'

import { seoEventManager } from '../../../config'

import { usedAttributes$ } from '../../usedAttributes'

import {
  fetchProjectListPending$,
  projectList$,
  fetchProjectList,
  filterService,
} from './model'

import { TabTitle } from './IssueManagerPage.styled'
import { useSetIssueSavedFilter } from './useSetIssueSavedFilter'

export const IssueManagerPage = () => {
  const [openedSwitchProjectPopup, setOpenedSwitchProjectPopup] =
    useState(false)
  const fetchProjectListPending = useStore(fetchProjectListPending$)
  const projectList = useStore(projectList$)
  const userInfo = useStore(userInfo$)
  const navigate = useNavigate()
  const selectedTab =
    (queryParser({
      key: SELECTED_TAB,
      options: { parseNumbers: true },
    }) as number) || TabIndex.IssueList
  const {
    appliedFilters: { setFilterValuesFromUrl },
  } = filterService[selectedTab]

  const projectUrn = queryParser({ key: PROJECT_URN }) as string

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn) || null,
    [projectList, projectUrn],
  )
  const queries = qs.parse(window.location.search)

  const { hasIssueFilterId } = useSetIssueSavedFilter({ selectedTab })

  useEffect(() => {
    if (hasIssueFilterId) {
      return
    }
    setFilterValuesFromUrl(queries)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  useEffect(() => {
    seoEventManager.push(
      {
        event: 'Gstation_Issues_ServiceEvent',
        payload: {
          projectUrn,
          userId: userInfo?.id || '',
        },
      },
      true,
    )
  }, [projectUrn, userInfo?.id])

  const onChangeTab = (nextTabIdx: number) => {
    const prev = qs.parse(window.location.search)
    const search = qs.stringify({
      ...prev,
      [SELECTED_TAB]: nextTabIdx.toString(),
    })
    navigate({ search })
  }

  const onChangeProject = useCallback(
    (project: smApi.Project) => {
      const prev = qs.parse(window.location.search)
      const search = qs.stringify({ ...prev, [PROJECT_URN]: project.urn })
      navigate({ search })
      seoEventManager.push(
        {
          event: 'Gstation_Issues_ServiceEvent',
          payload: {
            projectUrn,
            userId: userInfo?.id || '',
          },
        },
        true,
      )

      usedAttributes$.reset()
    },
    [navigate, projectUrn, userInfo?.id],
  )

  useEffect(() => {
    if (!projectUrn && projectList.length) {
      onChangeProject(projectList[0])
    }
  }, [onChangeProject, projectList, projectUrn])

  const onCloseSwitchProjectPopup = () => setOpenedSwitchProjectPopup(false)

  return (
    <IssueManagerWrapper
      selectedProject={selectedProject}
      brandSecondary={
        <SwitchProjectSelectWrapper>
          <SwitchProject
            projectList={projectList}
            disabled={fetchProjectListPending}
            loading={fetchProjectListPending}
            onChangeProject={onChangeProject}
            selectedProjectName={selectedProject?.name || ''}
            // badgeIcon={!selectedProject && <AllFolders />} //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
            onToggle={() => setOpenedSwitchProjectPopup(prev => !prev)}
            opened={openedSwitchProjectPopup}
            onClose={onCloseSwitchProjectPopup}
            renderItem={(project: smApi.Project, idx: number) => {
              const SwitchProjectItemJsx = (
                <SwitchProjectItem
                  onChangeProject={onChangeProject}
                  onClose={onCloseSwitchProjectPopup}
                  project={project}
                />
              )
              // if (idx === 0) {//TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
              //   return (
              //     <>
              //       <SwitchProjectPopoverItem
              //         onClick={() => {
              //           onChangeProject(null)
              //           onCloseSwitchProjectPopup()
              //         }}
              //       >
              //         <SwitchProjectProjectName>
              //           {allProjectItemName}
              //           <SwitchProjectPopoverItemCount>
              //             {projectList.length}
              //           </SwitchProjectPopoverItemCount>
              //         </SwitchProjectProjectName>
              //       </SwitchProjectPopoverItem>
              //       <SwitchProjectPopoverItemSeparator />
              //       {SwitchProjectItemJsx}
              //     </>
              //   )
              // }

              return SwitchProjectItemJsx
            }}
          />
        </SwitchProjectSelectWrapper>
      }
    >
      <PageContentWrapper>
        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={selectedTab}
          headerStyles={{
            fontSize: '14px',
            height: '49px',
            gap: 19,
          }}
          contentStyles={{
            height: '100%',
            paddingTop: '0',
            display: 'flex',
            minHeight: 0,
          }}
          tabs={[
            {
              title: <TabTitle>Замечания</TabTitle>,
              content: <IssueList projectList={projectList} />,
            },
            {
              title: <TabTitle>Шаблоны</TabTitle>,
              content: <IssueTemplateList projectList={projectList} />,
            },
          ]}
        />
      </PageContentWrapper>
    </IssueManagerWrapper>
  )
}

// const allProjectItemName = 'Все проекты'//TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
