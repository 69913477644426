import {
  Store,
  combine,
  createEffect,
  createEvent,
  createStore,
} from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'
import * as smApi from '@gmini/sm-api-sdk'

import { clone } from 'ramda'

import qs, { ParsedQuery } from 'query-string'

import { useNavigate } from 'react-router-dom'

import { assertNever } from '@gmini/utils'

import { useCallback } from 'react'

import {
  ASSIGNEES_IL,
  ATTRIBUTES_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  DEADLINE_CODE_IL,
  DEADLINE_RANGE_IL,
  AUTHOR_IDS_IL,
  RESET_ALL,
  SEARCH_IL,
  SORT_BY_FIELD_NAME_IL,
  SORT_BY_OPERATOR_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
  deadlineFilterMap,
  issueAvailableFilters,
  issueDateFilterMap,
  SHOW_IL,
} from '../constants'
import { MapQueryBy } from '../enum'

import { AssigneeListItem } from '../../createAssigneeGroupListService'

import {
  IssueListFiltersQueryType,
  IssueListFiltersType,
  IssueUpdateType,
  IssueUrlKeyType,
  PreparedIssueStatus,
} from '../types/issue.types'
import { ValueOf } from '../types/types'
import { AttributesService } from '../../attributesService'

const initState: IssueListFiltersQueryType = {
  showIL: 'all',
  searchIL: '',
  attributeValueIdsIL: [],
  statusesIL: [],
  authorIdsIL: [],
  assigneesIL: [],
  createdDateRangeIL: [],
  updatedDateRangeIL: [],
  createdDateCodeIL: null,
  updatedDateCodeIL: null,
  deadlineRangeIL: [],
  deadlineCodeIL: null,
  sortByOperatorIL: null,
  sortByFieldNameIL: null,
}

export const urlKeyMap: Record<
  keyof IssueListFiltersQueryType,
  ValueOf<typeof issueAvailableFilters>
> = {
  [SHOW_IL]: 'show',
  [SEARCH_IL]: 'filter',
  [ATTRIBUTES_IL]: 'attributeValueIds',
  [STATUSES_IL]: 'statuses',
  [AUTHOR_IDS_IL]: 'authorIds',
  [ASSIGNEES_IL]: 'assignees',
  [CREATED_DATE_RANGE_IL]: 'createdDateRange',
  [UPDATED_DATE_RANGE_IL]: 'updatedDateRange',
  [CREATED_DATE_CODE_IL]: 'createdDateCode',
  [UPDATED_DATE_CODE_IL]: 'updatedDateCode',
  [DEADLINE_RANGE_IL]: 'deadlineRange',
  [DEADLINE_CODE_IL]: 'deadlineCode',
  [SORT_BY_OPERATOR_IL]: 'sortByOperator',
  [SORT_BY_FIELD_NAME_IL]: 'sortByFieldName',
} as const
export const appliedFilters = ({
  issueStatusList$ = createStore<PreparedIssueStatus[]>([]),
  projectUserList$ = createStore<smApi.Auth.UserData[]>([]),
  attributeValueById$,
}: {
  issueStatusList$?: Store<PreparedIssueStatus[]>
  projectUserList$?: Store<smApi.Auth.UserData[]>
  attributeValueById$: AttributesService['attributeValueById$']
}) => {
  const parse = <Key extends keyof IssueListFiltersQueryType>(
    key: Key,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    switch (key) {
      case ASSIGNEES_IL: {
        try {
          return JSON.parse(value)
        } catch (error) {
          //TODO сбрасывать фильтры в урле, json.parse упал
          throw new Error(JSON.stringify(error))
        }
      }

      case STATUSES_IL:
      case AUTHOR_IDS_IL:
        return value.split(',')
      case ATTRIBUTES_IL:
        return value.split(',').map(Number)

      case SEARCH_IL:
      case SHOW_IL:
      case CREATED_DATE_RANGE_IL:
      case UPDATED_DATE_RANGE_IL:
      case CREATED_DATE_CODE_IL:
      case UPDATED_DATE_CODE_IL:
      case DEADLINE_RANGE_IL:
      case DEADLINE_CODE_IL:
      case SORT_BY_OPERATOR_IL:
      case SORT_BY_FIELD_NAME_IL:
        return value
      default:
        return assertNever('Not expected filter key from url', key)
    }
  }

  const updateArrayTypeValue = ({ state, key, value }: IssueUpdateType) => {
    if (!value) {
      return { ...state, [key]: [] }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentValue = Array.isArray((state as any)[key])
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((state as any)[key] as Array<string>)
      : []

    const isExist = !!currentValue.filter((el: string) => el === value).length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = isExist
      ? currentValue.filter(el => el !== value)
      : [...currentValue, value]

    return state
  }

  const updateObjectTypeValue = ({ state, key, value }: IssueUpdateType) => {
    //TODO под каждый тип фильра тут свой метод и свой входящий тип
    if (!value) {
      return { ...state, [key]: [] }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentValue = Array.isArray((state as any)[key])
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((state as any)[key] as Array<AssigneeListItem>)
      : []

    const isExist = !!currentValue.filter(
      (el: AssigneeListItem) =>
        `${el.source}_${el.assigneeId}` ===
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `${(value as any)?.source}_${(value as any)?.assigneeId}`,
    ).length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = isExist
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state as any)[key].filter(
          (el: AssigneeListItem) =>
            `${(el as AssigneeListItem).source}_${
              (el as AssigneeListItem).assigneeId
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }` !== `${(value as any).source}_${(value as any).assigneeId}`,
        )
      : [...currentValue, value]

    return state
  }

  const updateStringTypeValue = ({ state, key, value }: IssueUpdateType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(state as any)[key] = value

    return state
  }

  const insert = ({
    state,
    key,
    value,
  }: {
    state: IssueListFiltersQueryType
    key: keyof IssueUrlKeyType
    value: string | null
  }) => {
    switch (key) {
      case SHOW_IL:
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      case SEARCH_IL:
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      case ATTRIBUTES_IL:
        return updateArrayTypeValue({
          state,
          key,
          value,
        })
      case STATUSES_IL:
        return updateArrayTypeValue({
          state,
          key,
          value,
        })

      case UPDATED_DATE_CODE_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case CREATED_DATE_CODE_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case DEADLINE_CODE_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case AUTHOR_IDS_IL: {
        return updateArrayTypeValue({
          state,
          key,
          value,
        })
      }
      case ASSIGNEES_IL: {
        return updateObjectTypeValue({
          state,
          key,
          value,
        })
      }
      case SORT_BY_OPERATOR_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case SORT_BY_FIELD_NAME_IL: {
        return updateStringTypeValue({
          state,
          key,
          value,
        })
      }
      case RESET_ALL: {
        return initState
      }
      default:
        return state
    }
  }

  const setFilterValuesFromUrl = createEffect((query: ParsedQuery) =>
    Object.entries(query).reduce((acc: IssueListFiltersQueryType, [k, v]) => {
      if (
        Object.prototype.hasOwnProperty.call(acc, k) &&
        typeof v === 'string'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(acc as any)[k] = parse(k as keyof IssueListFiltersQueryType, v)
      }

      return acc
    }, clone(initState)),
  )

  const updateFilter = createEvent<IssueUrlKeyType>()
  const useUpdateFilter = () => {
    const navigate = useNavigate()

    const updateUrlHandler = useCallback(() => {
      const prev = qs.parse(window.location.search)
      const filters = filtersByQueryKey$.getState()
      const queryParams = qs.stringify(
        {
          ...prev,
          ...filters,
          assigneesIL: filters.assigneesIL.length
            ? JSON.stringify(filters.assigneesIL)
            : [],
        },
        {
          arrayFormat: 'separator',
          arrayFormatSeparator: ',',
          skipNull: true,
          skipEmptyString: true,
        },
      )

      navigate({ search: queryParams })
    }, [navigate])

    const update = useCallback(
      (filter: IssueUrlKeyType) => {
        updateFilter(filter)
        updateUrlHandler()
      },
      [updateUrlHandler],
    )

    const applyFilters = useCallback(
      (filter: ismApi.Filters.IssueFilterType) => {
        applyFilter(filter)
        updateUrlHandler()
      },
      [updateUrlHandler],
    )

    return { update, applyFilters }
  }
  const applyFilter = createEvent<ismApi.Filters.IssueFilterType>()

  const filtersByQueryKey$ = createStore<IssueListFiltersQueryType>(initState)
    .on(setFilterValuesFromUrl.doneData, (state, newFilters) => ({
      ...state,
      ...newFilters,
    }))
    .on(updateFilter, (state, filters) => {
      let newSate = clone(state)
      const entries = Object.entries(filters)
      entries.forEach(([key, value]) => {
        if (newSate) {
          newSate = insert({
            state: newSate,
            key: key as keyof IssueUrlKeyType,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: value as any,
          })
        }
      })

      return newSate
    })
    .on(applyFilter, (_, filters) =>
      Object.entries(urlKeyMap).reduce((acc, [k, v]) => {
        //TODO опасный any, избавится в первую очередь
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(acc as any)[k] = (filters as any)[v as string]

        return acc
      }, {} as IssueListFiltersQueryType),
    )

  const appliedFilters$ = filtersByQueryKey$.map(filters =>
    Object.entries(filters).reduce((acc, [k, v]) => {
      const key = urlKeyMap[k as keyof IssueListFiltersQueryType]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(acc as any)[key as string] = v

      return acc
    }, {} as IssueListFiltersType),
  )

  const detailedFilters$ = combine(
    appliedFilters$,
    issueStatusList$,
    projectUserList$,
    attributeValueById$,
    (filters, issueStatuses, userList, attributeValueById) => {
      const { show } = filters
      const statuses = filters.statuses.reduce(
        (acc: { bgColor: string; name: string }[], statusName) => {
          const filter = issueStatuses.find(item => item.status === statusName)
          if (filter) {
            acc.push({ name: filter.name, bgColor: filter.bgColor })
          }
          return acc
        },
        [],
      )

      const updatedDateCode = filters.updatedDateCode
        ? issueDateFilterMap[filters.updatedDateCode]
        : ''

      const createdDateCode = filters.createdDateCode
        ? issueDateFilterMap[filters.createdDateCode]
        : ''

      const deadlineCode = filters.deadlineCode
        ? deadlineFilterMap[filters.deadlineCode]
        : ''

      const authorIds = filters.authorIds.map(author => {
        const currentAuthor = userList.find(user => user.id === author)
        return currentAuthor ? currentAuthor.name : ''
      })

      const assignees = filters.assignees.map(assignee => assignee.label)

      const attributeValues = filters.attributeValueIds
        .map(id => attributeValueById[id])
        .filter(Boolean)

      return {
        show,
        statuses,
        updatedDateCode,
        createdDateCode,
        deadlineCode,
        authorIds,
        assignees,
        attributeValues,
      }
    },
  )

  const mapQueryParams = ({
    by,
    params,
  }: {
    by: MapQueryBy
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: Record<string, any>
  }) => {
    const a = Object.entries(urlKeyMap).reduce((acc, [k, v]) => {
      const key = by === MapQueryBy.UrlKey ? k : v
      const paramsKey = by === MapQueryBy.UrlKey ? v : k
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (params as any)[paramsKey as string]
      if (value) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(acc as any)[key as string] = value
      }

      return acc
    }, {})
    return a
  }

  return {
    setFilterValuesFromUrl,
    updateFilter,
    useUpdateFilter,
    appliedFilters$,
    detailedFilters$,
    mapQueryParams,
  }
}
